//@use "sass:math" as math;
/**
 * Import media breakpoints and other shraed vars (js/css)
 */
@import 'shared-vars';
@import 'vars-figma-colors';

/**
 * Use autogenerated media in media mixins
 */
$media-steps: $sx-breakpoints;

$grid: (
        steps: (1, 12),
        gutters: (48),
        breakpoint-gutters: (
                'xs': 20px,
                'sm': 20px,
                'md': 48px,
                'lg': 48px,
                'xl': 48px,
                'xxl': 48px,
        ),
        container-paddings: (
                'xs': 20px,
                'sm': 20px,
                'md': 64px,
                'lg': 64px,
                'xl': 64px,
                'xxl': 64px,
        ),
        container-paddings-fluid: (
                'xs': 20px,
                'sm': 20px,
                'md': 64px,
                'lg': 64px,
                'xl': 64px,
                'xxl': 64px,
        )
);

// bulid any combination of the numbers. Please reduce the numbers, if possible.
$image-ratio-steps: (
        1, 2, 3, 4, 5, 7, 9, 16, 18
);

$sx-path-images: '/dist/img/';

//comes from config/shared_vars.json

$header-height: $sx-headerheight;
$header-height-mobile: $sx-headerheight-mobile;


/**
 * Spacing vars
 */

$spacing-m: pxToRem(28); // TODO @zelli (?) aus der Filterbase bitte rausnehmen! Wenn wir das variabel haben wollen, dann lass uns doch eine $filter-spacing machen!


$modulspacing-desktop: pxToRem(128);

$modulspacing-desktop-m: $modulspacing-desktop; // Standard

$modulspacing-mobile-m: pxToRem(72); // Standard


/**
 * Color vars
 */

$color-primary: $color-primary-800;
$color-primary-hover: scale_color($color-primary, $lightness: 30%) !default; /*Var for convenience*/

$color-secondary: $color-secondary-800;
$color-secondary-hover: scale_color($color-secondary, $lightness: 30%) !default; /*Var for convenience*/

$color-tertiary: #3FC1C9;
$color-tertiary-hover: scale_color($color-tertiary, $lightness: 30%) !default; /*Var for convenience*/

$color-success: $color-success-800;
$color-warning: $color-warning-800;
$color-danger: $color-error-800;
$color-info: #37C4EF;

$color-background: $color-primary;

/**
 * Font vars
 */

$sx-path-fonts: '/dist/font/';

$base-font-size: 16px;
$base-font-weight: 400;

$font-family-primary: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-secondary: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
//$font-family-tertiary: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-color: $color-black; // definiert die Fliesstextfarbe!

$font-color-primary: $font-color;
$font-color-secondary: $color-black; // ggf. für Headline etc.
//$font-color-tertiary: $color-black;


/**
 * Border radius vars
 */

$border-radius: 0px !default;
$border-radius-button: 5px;
